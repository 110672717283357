"use client";

import { useEffect } from "react";
import logger from "@/lib/logging";
import Sidebar from "@/app/dashboard/side-bar/dashboard-sidebar";

// TODO: Proper error handling here
export default function Page({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    logger.error(error);
  }, [error]);
  return (
    <div>
      <Sidebar qaMode={true} />
      <div className="flex justify-center items-center h-screen">
        <div className="text-center">
          <h1 className="text-lg mb-4">Seems like we ran into an issue.</h1>
          <p className="text-lg text-bold">Please sign out and sign back in.</p>
        </div>
      </div>
    </div>
  );
}
